import { Component, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import Quagga from 'quagga/dist/quagga';
//import { } from 'googlemaps';

import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent } from '../new-parcel/autocompleteAddress';
import { GridOptions } from "ag-grid-community/main";
import { QuestionService } from './../question.service';
import { QuestionBase } from './../question-base';
import { DataService } from './../data.service';
import { SlideInOutAnimation } from './../animations';
import { Parcel } from './../parcel-model';
import { NumericEditor } from './../numeric-editor.component';
import { DropdownEditor, DropdownRenderer } from './../dropdown-editor.component';

declare var $: any;

@Component({
  selector: 'app-new-money',
  animations: [SlideInOutAnimation],
  templateUrl: './new-money.component.html',
  styleUrls: ['./new-money.component.css']
})
export class NewMoneyComponent implements OnInit, AfterViewInit {
  questions: QuestionBase<any>[];
  questions_sender: QuestionBase<any>[];
  questions_receiver: QuestionBase<any>[];
  countries: any[];
  countryStates: any[];
  oblasts: any[];
  deliverySelection: any[];
  itemGroups: any[];
  txtOK = 'OK';
  isMobile: boolean;
  doSender: boolean;
    discountID: number;
    address: any;
  pageno: number;
  page1: any;
  page2: any;
  page3: any;
    page4: any;
    pageMap: any;
  page5: any;
    animationState = ['in', 'out', 'out', 'out', 'out', 'out'];
    //sender_map = {
    //    sapartn: 'u_apart', scity: 'u_city', scountry: 'u_country', semail: 'u_email', sfname: 'u_firstnm', slname: 'u_lastnm', soblast: 'u_oblast', sphone: 'u_phone', sregion: 'u_region', sstreet: 'u_street', sstreetn: 'u_house', szip: 'u_index'
    //};
  pageTitle: string;
  showScanner = false;
  scannedData: string;
  errorMessage: string;
    @ViewChild('form', { static: false }) formComp;
  @Output() onSubmitted = new EventEmitter<Parcel>();
    @ViewChild('holdModal', { static: false }) holdModal;
    @ViewChild('brokLimit', { static: false }) brokLimit;
    bsModalRef: BsModalRef;
    maxAmount: number = 1000;
    config: any;

  //private gridOptions: GridOptions;
  //public rowData: any[];
  //private columnDefs: any[];
  //private gridApi;
  //private gridColumnApi;

  private grid2Options: GridOptions;
  public rowData2: any[];
  private columnDefs2: any[];
  private columnDefs2Freq: any[];
  private frameworkComponents2;
  private grid2Api;

    constructor(private service: QuestionService, private dataService: DataService, private route: ActivatedRoute, private router: Router, private modalService: BsModalService) {
    this.isMobile = this.dataService.isMobileDevice;
    if (!this.isMobile && window.innerWidth < 992)
      this.isMobile = true;

      this.grid2Options = <GridOptions>{
          columnDefs: this.columnDefs2
    };

    this.frameworkComponents2 = { numericEditor: NumericEditor, dropdownEditor: DropdownEditor, dropdownRenderer: DropdownRenderer };

    this.columnDefs2 = [
      {
            headerName: "Group Name", field: "groupname", minWidth: 200, colId: "Col1",
        editable: true,
        cellRenderer: "dropdownRenderer",
        cellRendererParams: { options: [] },
        cellEditor: "dropdownEditor",
        cellEditorParams: { options: [] }
      },
        {
            headerName: "Quantity", editable: true, cellEditor: "numericEditor", field: "quantity", minWidth: 90, headerClass: 'leftHeader', cellClass: 'leftText', cellEditorParams: (par) => { return { value: par.value, focus: true }; } },
      { headerName: "Description", editable: true, cellEditor: "agTextCellEditor", field: "description", minWidth: 110, },
        { headerName: "Weight", editable: true, cellEditor: "numericEditor", field: "weight", type: "numericColumn", minWidth: 90, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
        { headerName: "Value", editable: true, cellEditor: "numericEditor", field: "value", type: "numericColumn", minWidth: 80, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
    ];

      this.columnDefs2Freq = [
          { headerName: "Group Name", field: "groupname", minWidth: 200, editable: false, colId: "Col1Freq", },
          { headerName: "Quantity", editable: true, cellEditor: "numericEditor", field: "quantity", minWidth: 90, headerClass: 'leftHeader', cellClass: 'leftText', cellEditorParams: (par) => { return { value: par.value, focus: true }; } },
          { headerName: "Description", editable: true, cellEditor: "agTextCellEditor", field: "description", minWidth: 110, },
          { headerName: "Weight", editable: true, cellEditor: "numericEditor", field: "weight", type: "numericColumn", minWidth: 90, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
          { headerName: "Value", editable: true, cellEditor: "numericEditor", field: "value", type: "numericColumn", minWidth: 80, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
      ];

    this.rowData2 = [{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: 0 }];

        zip(this.dataService.getItemGroups(), this.dataService.getMoneyConfig()).subscribe((ui: any[]) => {
            this.itemGroups = ui[0];
            this.config = ui[1];
            this.maxAmount = this.config["MAXAMOUNT"];

          let dd = this.columnDefs2.find(x => x.field == 'groupname');
          dd.cellEditorParams.options = ui.map(o => { return { key: o.Value, value: o.Text } });

          dd.cellRendererParams.options = dd.cellEditorParams.options;
    });
  }

  ngOnInit() {
    this.setupQuagga();

    this.route.url.subscribe(url => {
      console.log(url);
      this.init();
    });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.holdModal.show();
        })
    }

  init() {
    this.pageno = -1;  
    this.animationState = ['in', 'out', 'out', 'out', 'out', 'out'];
    this.txtOK = 'Next';
      this.pageTitle = 'Select Meest Drop-off Location';
    this.discountID = null;
    this.errorMessage = '';

    this.rowData2 = [{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: 0 }];
    }

    goahead() {
        zip(this.dataService.getCountryState(), this.dataService.getAgent('MEEST')).subscribe((res: any[]) => {
            this.countryStates = res[0];

            let agent_addrComp = {};
            let user_addrComp = {};
            let addrComp = this.dataService.parseAgentAddress(res[1], agent_addrComp);
            let addrComp_u = this.dataService.parseUserAddress(user_addrComp);
            this.pageno = 1;
            this.submitted(JSON.stringify({ userAddress: user_addrComp, agentAddress: agent_addrComp, agent: res[1] }));  //  Skip the map page;
        });
    }

  //onGridReady(params) {
  //  this.gridApi = params.api;
  //  this.gridColumnApi = params.columnApi;

  //  this.gridApi.setRowData(this.deliverySelection);

  //  this.autoSizeAll(params.columnApi);
  //}
  
  //onRowDataChanged1(params) {
  //  params.columnApi.autoSizeColumns();
  //}
  
  //disableGrid() {
  //  return this.gridApi && this.gridApi.getSelectedRows().length > 0 ? false : true;
  //}

  currencyFormatter(params) {
      return params.value ? params.value.toFixed(2) : '';
    //return "\xA3" + formatNumber(params.value);
  }


  onGrid2Ready(params) {
    this.grid2Api = params.api;

    this.grid2Api.setRowData(this.rowData2);

    params.columnApi.autoSizeColumns()

    this.grid2Api.startEditingCell({
      rowIndex: 0,
      colKey: 'Col1'
    });
  }

  autoSizeAll(colapi: any) {
    var allColumnIds = [];
    colapi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    colapi.autoSizeColumns(allColumnIds);
  }

  onCheckChange(arg: any) {
    if (arg.key == 'rcountry' && arg.value !== '') {
      let c = this.questions.find(x => x.key == 'rcountry');
      var qx = this.questions.findIndex(x => x.key == 'roblast');
      let q = this.questions[qx];
        q.value = arg.value;
        let selc = this.countries.find(x => x.CountryCodeValue == arg.value);
        if (selc && selc.CountryCodeType === 'DropDownTable') {
            this.dataService.getOblast(selc.r_country).subscribe((obl: any[]) => {
              this.oblasts = obl;
              q['options'] = obl.map(c => {
                return { key: c.Id, value: c.r_region }
              });
              q['options'].splice(0, 0, { key: '', value: 'Select Oblast' });
              q.hidden = false;
              q.required = true;
              this.questions[qx] = Object.assign({}, q);
            })
      } else {
          this.oblasts = [];
          q.hidden = true;
          this.questions[qx] = Object.assign({}, q);
      }
    }

      if (arg.key == 'scountry' && arg.value !== '' && this.countryStates[arg.value.toUpperCase()]) {
      var qx = this.questions_sender.findIndex(x => x.key == 'soblast');
          this.questions_sender[qx]['options'] = this.countryStates[arg.value.toUpperCase()].map(c => {
        return { key: c.state, value: c.desc }
      });

      this.questions_sender[qx] = Object.assign({}, this.questions_sender[qx]);
    }
}

    onCheckConfirm(arg: any) {
        if (arg.type == 'checkbox') {
            if (!arg.value)
                this.formComp.setDisabled(true);
            else if (this.formComp.form.controls['txt1'].value == '1' && (this.formComp.form.controls['txt2'].value == '1' || this.formComp.form.controls['insamt'].value != '0.00'))
                this.formComp.setDisabled(false);
        }
        else if (arg.type == 'dropdown') {
            if (arg.value == '0.00') {
                this.questions.find(x => x.key == 'txt2').hidden = false;
                if (this.formComp.form.controls['txt1'].value == '1' && (this.formComp.form.controls['txt2'].value == '1' || this.formComp.form.controls['insamt'].value != '0.00'))
                    this.formComp.setDisabled(false);
                else
                    this.formComp.setDisabled(true);
            }
            else {
                this.questions.find(x => x.key == 'txt2').hidden = true;
                if (this.formComp.form.controls['txt1'].value == '1')
                    this.formComp.setDisabled(false);
                else
                    this.formComp.setDisabled(true);
            }

            this.formComp.updateQuestions(['txt2']);
        }
    }

  addRow() {
    this.rowData2.push({ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: this.rowData2.length });

    this.grid2Api.setRowData(this.rowData2);
  }

  deleteRow() {
    var row = this.grid2Api.getSelectedRows()[0];
    if (!row)
      return;
    var ix = row["seqno"];

    this.rowData2.splice(ix, 1);
    for (var i = 0; i < this.rowData2.length; i++)
      this.rowData2[i]["seqno"] = i;

    this.grid2Api.setRowData(this.rowData2);
  }

  hasRows() {
      return this.rowData2.some(x => x.groupname !== '' && x.quantity != '');
  }

  onDiscount(id) {
    if (id > 0)
      this.discountID = id;
    this.submitted('grid_d');
  }

  submitted(payLoad: string) {
    if (payLoad && payLoad !== '') {

        if (this.pageno == 1) {
            if (this.dataService.userData.UserData.u_type == 'CUSTOMER') {
                this.pageMap = JSON.parse(payLoad);
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.pageMap.agent.branch);
            }
            else
                this.pageMap = null;

            this.dataService.getMoneyCountries().subscribe((res: any[]) => {
                this.countries = res;

                this.questions = this.service.getQuestionsNewMoney().map((q) => {
                    return Object.assign({}, q);
                });
                //this.questions.find(x => x.key == 'txt2').label = `Maximum amount is ${this.maxAmount} ${this.config["CURRENCY"]} including all fees`;

                let cq = this.questions.find(x => x.key == 'rcountry');
                cq['options'] = this.countries.map(c => {
                    return { key: c.CountryCodeValue, value: c.r_country }
                });
                cq['options'].splice(0, 0, { key: '', value: 'Select Country' });

                this.pageTitle = 'Enter Transfer Details';
                this.nextPage();
            });
        }

      if (this.pageno == 2) {
          this.page1 = JSON.parse(payLoad);

          if (Number(this.page1["amount"]) > this.maxAmount) {
              this.brokLimit.show();
              return;
          }

          this.page1["rcountrycode"] = this.page1.rcountry;
          this.page1.rcountry = this.countries.find(x => x.CountryCodeValue == this.page1.rcountry).r_country;

          let param = { CountryCode: this.page1["rcountrycode"], Amount: this.page1["amount"], Currency: 'USD', Mode: 'I', UserID: this.dataService.userData.UserData.id };

          this.dataService.getMoneyTransferSelection(param).subscribe((ui: any[]) => {
              if (ui.length > 0 && ui[0].TransferMode == 'Error')
                  this.errorMessage = ui[0].DeliveryModeDescription;
              else {
                  this.errorMessage = '';
                  this.deliverySelection = ui;
                  this.pageTitle = 'Select Transport and Delivery';
                  this.nextPage();
              }
          });
      }

      if (this.pageno == 3) {
          this.page2 = JSON.parse(payLoad);  // this.gridApi.getSelectedRows()[0];
          this.page2 = this.page2.delivery;
          //this.gridApi.setRowData([]);

        this.pageTitle = 'Enter Address';
        this.nextPage();
      }

      if (this.pageno == 4) {
          this.page3 = JSON.parse(payLoad);
          //this.pageno++;

          //this.pageTitle = 'Select Discount';  //  Remove discount
          //this.nextPage();

      //    this.pageTitle = 'Select Agent'; 
      //    this.nextPage();
      //}

      //if (this.pageno == 5) {
          this.questions = this.service.getQuestionsNewMoney4().map((q) => {
              return Object.assign({}, q);
          });
          this.questions_sender = [];
          this.questions_receiver = [];
        //this.dataService.insertQuestions(this.questions, 'ParcelDetails').subscribe((obl: any[]) => {
        //},
        //  (err: any) => {
        //    this.errorMessage = this.dataService.processError(err);
        //  });

        //this.questions.find(x => x.key == 'weight').value = this.page1.weight;
        //this.questions.find(x => x.key == 'h').value = this.page1.h;
        //this.questions.find(x => x.key == 'l').value = this.page1.l;
        //this.questions.find(x => x.key == 'w').value = this.page1.w;
        this.questions.find(x => x.key == 'amount').value = this.page1.amount;
        this.questions.find(x => x.key == 'delmode').value = this.page2.DeliveryModeDescription;
          this.questions.find(x => x.key == 'payamount').value = this.page2.TotalCustomerOwe_CAD;
          this.questions.find(x => x.key == 'amount').label += ' USD';
          this.questions.find(x => x.key == 'payamount').label += (' ' + this.dataService.userData.Branch.currency);
        //this.questions.find(x => x.key == 'weight').label += (' ' + this.dataService.userData.Branch.weight);
        //this.questions.find(x => x.key == 'txt2').value = '0';

        //if (this.isMobile) {
        //  this.questions.find(x => x.key == 'h').width += ' col-xs-3 ';
        //  this.questions.find(x => x.key == 'l').width += ' col-xs-3 ';
        //  this.questions.find(x => x.key == 'w').width += ' col-xs-3 mb15 ';
        //}

          if (!this.isMobile) {
              this.questions_sender = this.questions.filter(x => !x["readonly"] && x.controlType != 'label' && x.key != 'declaration').sort((a, b) => a.order - b.order);
              this.questions_receiver = this.questions.filter(x => x["readonly"] || x.controlType == 'label' || x.key == 'declaration').sort((a, b) => a.order - b.order);
          }

          //this.formComp.setDisabled(true);
          this.pageTitle = 'Complete and Review';
          this.nextPage();
      }

      if (this.pageno == 5) {
        //  TODO: check ParcelMaxValue
          this.page4 = JSON.parse(payLoad);

          let model = this.buildModel(this.page1, this.page2, this.page3, this.page4, this.page5);

          this.errorMessage = '';
          this.dataService.saveMoney(model).subscribe((ui: number) => {
              model.id = ui;

              this.router.navigate(["payment", model.id, 1]);
          },
              (err: any) => {
                  this.errorMessage = this.dataService.processError(err);
              });
      }
    }
    else {
      this.questions = [];
      this.questions_sender = [];
      this.questions_receiver = [];
      this.animationState[0] = 'out';
      this.animationState[1] = 'out';
      this.animationState[2] = 'out';
      this.animationState[3] = 'out';
      this.animationState[4] = 'out';
      this.router.navigate(["home"]);
    }
  }

  private nextPage() {
    var page = this.pageno - 1;
    setTimeout(() => {
      this.pageno++;
      setTimeout(() => {
        this.animationState[page] = this.animationState[page] === 'out' ? 'in' : 'out';
        this.animationState[page+1] = this.animationState[page+1] === 'out' ? 'in' : 'out';
        $('html, body').animate({ scrollTop: '0px' }, 300);

        if (this.pageno == 5) {
          let me = this;
          $('#scanButton').off('click').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            //$('#declaration').focus();
            $('#declaration').val('');
            me.scannedData = '';

            me.showScanner = !me.showScanner;
            if (me.showScanner)
              setTimeout(() => me.startQuagga());
            else
              Quagga.stop();

            return false;
          });
        }
      });
    });
  }

  onFormInited(page) {
    if (page === 'ParcelReceiver') {
        initAutocomplete(this.updateStateList.bind(this));
        restrictCountry(this.questions_receiver.find(x => x.key == 'rcountry').value);
    }
    if (page === 'ParcelSender')
      initAutocomplete_s(this.updateStateList.bind(this));
  }

    updateStateList(country, stateprov, elems) {
        if (elems) {
            elems.forEach(x => this.formComp.form.controls[x.key].patchValue(x.value) );
        }
        else {
            if (!this.countryStates[country.toUpperCase()])
                return;

            this.questions_sender.find(x => x.key == 'scountry').value = country.toUpperCase();
            this.formComp.updateQuestions(['scountry']);

            var qx = this.questions_sender.findIndex(x => x.key == 'soblast');
            this.questions_sender[qx]['options'] = this.countryStates[country.toUpperCase()].map(c => {
                return { key: c.state, value: c.desc }
            });

            //this.questions_sender[qx] = Object.assign({ value: stateprov }, this.questions_sender[qx]);
            this.questions_sender[qx].value = stateprov;
            this.formComp.updateQuestions(['soblast']);
        }
        return false;
  }

  buildModel(p1: any, rates: any, address: any, p4: any, p5: any): Parcel {
    let model = new Parcel();
    model.id = 0;

    model.userID = this.dataService.userData.UserData.id;
    model.branchID = this.dataService.userData.Branch.id;
    model.source = this.dataService.userData.UserData.u_email;
      model.customerCD = this.dataService.userData.UserData.customerCD;
      model.currencyCD = 'USD';
      model.TotalCustomerOwe_USD = p1.amount;

    model.DeliveryMode = rates.DeliveryModeCode;
      model.RateName = rates.TransferMode;
      model.value = rates.TotalCustomerOwe_CAD;
    model.vol_fee = rates.volume_fee;
    model.vol_wght = rates.VolumeWeight;
    model.rate = rates.rate;
    model.rate_fee = rates.rate_fee;
    model.delivery = rates.delivery;
    model.rate_fee_meest = rates.rate_fee_meest;
    model.rate_fee_agent = rates.rate_fee_agent;
    model.rate_fee_ws = rates.rate_fee_ws;
    model.delivery_meest = rates.delivery_meest;
    model.delivery_agent = rates.delivery_agent;
    model.delivery_ws = rates.delivery_ws;
    model.ins_meest = rates.ins_meest;
    model.ins_agent = rates.ins_agent;
    model.ins_ws = rates.ins_ws;
    model.Packaging_meest = rates.Packaging_meest;
    model.Packaging_agent = rates.Packaging_agent;
    model.Packaging_ws = rates.Packaging_ws;

    model.s_phone = address.sphone;
    model.s_email = address.semail;
    model.s_apart = address.sapartn;
    model.s_city = address.scity;
    model.s_country = address.scountry;
    model.s_firstnm = address.sfname;
    model.s_house = address.sstreetn;
    model.s_index = address.szip;
    model.s_oblast = address.soblast;
    model.s_lastnm = address.slname;
    model.s_region = address.sregion;
    model.s_street = address.sstreet;

    model.r_phone = address.rphone;
    model.r_email = address.remail;
    model.r_apart = address.rapartn;
    model.r_city = address.rcity;
    model.r_country = address.rcountry;
    model.r_CountryCode = p1.rcountrycode;
    model.r_firstnm = address.rfname;
    model.r_house = address.rstreetn;
    model.r_index = address.rzip;
    model.r_oblast = address.roblast;
    model.r_lastnm = address.rlname;
    model.r_region = address.rregion;
    model.r_street = address.rstreet;

    model.notes = p4.notes;
    model.code_a = (!p4.declaration || p4.declaration == '') ? null : p4.declaration;
    model.DiscountID = this.discountID;

    return model;
    }

  startQuagga() {
    let me = this;

    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        //target: document.querySelector('#yourElement')    // Or '#yourElement' (optional)
      },
      decoder: {
        //readers: ["codabar_reader"]
        readers: ["code_128_reader"]
      }
    }, function (err) {
      if (err) {
        console.log(err);
        me.showScanner = false;
        alert(err);
        return;
      }
      console.log("Initialization finished. Ready to start");
      Quagga.start();
    });
  }

  setupQuagga() {
    Quagga.onProcessed((result) => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
            return box !== result.box;
          }).forEach(function (box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    Quagga.onDetected((result) => {
      $('#declaration').val(result.codeResult.code);
      this.scannedData = result.codeResult.code;
      Quagga.stop();
      this.showScanner = false;
    });
  }
}
