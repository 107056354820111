import { Component, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, zip, concat } from 'rxjs';
import { last, toArray } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import Quagga from 'quagga/dist/quagga';
//import { } from 'googlemaps';
import intlTelInput from 'intl-tel-input';
import { CodeInputComponent } from 'angular-code-input';
import * as moment from 'moment';

import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent, restrictCountry_s } from './autocompleteAddress';
import { GridOptions } from "ag-grid-community/main";
import { QuestionService } from './../question.service';
import { QuestionBase } from './../question-base';
import { DataService } from './../data.service';
import { SlideInOutAnimation } from './../animations';
import { Parcel } from './../parcel-model';
import { NumericEditor } from './../numeric-editor.component';
import { DropdownEditor, DropdownRenderer } from './../dropdown-editor.component';
import { TypeaheadEditor } from './../typeahead-editor.component';
import { ModalMapComponent } from './modal-map.component';
import { SignatureComponent } from './signature.component';
import { PopupEstimatorComponent } from '../popup-estimator/popup-estimator.component';
import { PaymentComponent } from '../payment/payment.component';
import { RegistrationComponent } from '../registration/registration.component';

declare var google: any;
declare var $: any;
import '../../../node_modules/jquery/dist/jquery.min.js';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js";

@Component({
    selector: 'app-new-parcel',
    animations: [SlideInOutAnimation],
    templateUrl: './new-parcel2.component.html',
    styleUrls: ['./new-parcel2.component.css']
})
export class NewParcel2Component implements OnInit, AfterViewInit {
    questions: QuestionBase<any>[];
    countries: any[];
    countryStates: any[];
    oblasts: any[];
    oblastOpt: any[] = [];
    deliverySelection: any[];
    itemGroups: any[];
    txtOK = 'OK';
    pickupWindow: any;
    doSender: boolean;
    closestAgent: any;
    discountID: number;
    discountValue: number = 0;
    address: any;
    phase0: boolean = true;
    agent_drop: boolean = false;
    pageno: number;
    page1: any;
    page2: any;
    page3: any;
    page4: any;
    pageMap: any;
    page5: any;
    pickupAddress: any;
    post_agent: string;
    meestAgent: any = {};
    animationState = ['in', 'out', 'out', 'out', 'out', 'out'];
    freqButtonText = ['Frequently Sent Items', 'More Items'];
    freqButtonState: number;
    phoneValidation: any = {};
    //sender_map = {
    //    sapartn: 'u_apart', scity: 'u_city', scountry: 'u_country', semail: 'u_email', sfname: 'u_firstnm', slname: 'u_lastnm', soblast: 'u_oblast', sphone: 'u_phone', sregion: 'u_region', sstreet: 'u_street', sstreetn: 'u_house', szip: 'u_index'
    //};
    parcelModel: any;
    pageTitle: string;
    currencyId: string = '';
    receivingCountry: any = {};
    showScanner = false;
    scannedData: string;
    errorMessage: string;
    phoneMessage: string;
    showSignature: boolean = false;
    showPayment: boolean = false;
    isPaid: boolean = false;
    @ViewChild('form', { static: false }) formComp;
    @Output() onSubmitted = new EventEmitter<Parcel>();
    bsModalRef: BsModalRef;
    @ViewChild('hazModal', { static: false }) hazModal;
    @ViewChild('brokLimit', { static: false }) brokLimit;
    @ViewChild('history', { static: false }) history;
    @ViewChild('pickupModal', { static: false }) pickupDialog;
    @ViewChild('dpicker', { static: false }) dpicker;
    @ViewChild('verCode', { static: false }) verCode;
    verCodeInput: string = '';
    verCodeSent: string = '';
    verCodeError: boolean = false;
    verHow: string = 'phone';
    @ViewChild('codeInput', { static: false }) codeInput !: CodeInputComponent;
    @ViewChild('estimator0', { static: false }) estimator0: PopupEstimatorComponent;
    @ViewChild('estimator1', { static: false }) estimator1: PopupEstimatorComponent;
    @ViewChild('signature', { static: false }) signature: SignatureComponent;
    @ViewChild('payment', { static: false }) payment: PaymentComponent;
    @ViewChild('disclaimer', { static: false }) disclaimer;
    inited: boolean = false;
    declaredValue: number;
    BrokerageLimit: number;
    UserBranch: number;
    saving: boolean = false;
    preselectedRate: any;
    noCarouselPause = false;
    useBillingAddress = false;
    form1: FormGroup;
    form2: FormGroup;
    form3: FormGroup;
    formba: FormGroup;
    @ViewChild('baComp', { static: false }) baComp: RegistrationComponent;
    itiR: any;
    itiS: any;
    emailRequired: boolean = false;
    insOpt: any[] = [];
    sigdata: any;
    payMethods: any[];
    paycode: string;
    payText: string = '';
    histList: any[] = [];
    dpOptions = {};
    discError: string = '';
    selectedItems: any[] = [];
    shipType = 'I';
    express: boolean = false;
    passportReqd: string[] = ['Uzbekistan', 'Armenia'];
    expressShipCountries: string[] = ['NGA', 'GHA', 'IND', 'PHI', 'AUT', 'CAN', 'VET', 'PAK', 'COL', 'MEX',
        ,'ARE'
        ,'ARG'
        ,'BOL'
        ,'BRA'
        ,'CHL'
        ,'ECU'
        ,'PER'
        ,'NZL'
        ,'IDN'
        ,'SAU'
        ,'DOM'
        ,'JAM'
        ,'ZAF'
        ,'TJK'
        ,'TKM'
        ,'CHN'
        ,'JPN'
    ];

    accordionMenu: boolean[] = [true, false, false, false, false, false, false];
    accordionCompleted: boolean[] = [false, false, false, false, false, false, false];

    public grid2Options: GridOptions;
    public grid2OptionsMobile: GridOptions;
    public rowData2: any[] = [];
    private columnDefs2: any[];
    private columnDefs2Freq: any[];
    public frameworkComponents2;
    private grid2Api;
    private grid2ColumnApi;

    constructor(private service: QuestionService, private dataService: DataService, private route: ActivatedRoute, private router: Router, private modalService: BsModalService, private fb: FormBuilder, private datePipe: DatePipe) {
        this.grid2Options = <GridOptions>{
            columnDefs: this.columnDefs2,
            defaultColDef: { resizable: true },
            suppressRowClickSelection: true,
            onCellEditingStarted: (params: any) => {
                if (params.colDef.colId == 'Col1Freq') {
                    this.dataService.addClass(document.getElementById('mobpct'), 'no-overflow');
                }
            },
            onCellEditingStopped: (params: any) => {
                if (params.colDef.colId == 'Col1Freq') {
                    this.dataService.removeClass(document.getElementById('mobpct'), 'no-overflow');
                    if (!params.value && this.rowData2.length > 1) {
                        for (var i = params.rowIndex + 1; i < this.rowData2.length; i++)
                            this.selectedItems[i - 1] = this.selectedItems[i];
                        this.selectedItems[this.rowData2.length - 1] = null;
                        this.rowData2.splice(params.rowIndex, 1);
                        this.rowData2 = [].concat(this.rowData2);
                    }
                    else if (this.selectedItems[params.rowIndex] && params.value != this.selectedItems[params.rowIndex].name) {
                        this.selectedItems[params.rowIndex] = null;
                    }
                    else {
                        if (!params.data.quantity)
                            setTimeout(() => params.api.flashCells({ rowNodes: [params.node], columns: ['quantity'] }));
                        this.onFreqAdded();
                    }
                }
            },
        };
        this.grid2OptionsMobile = <GridOptions>{
            columnDefs: this.columnDefs2,
            defaultColDef: { resizable: true },
            suppressRowClickSelection: true,
            onCellEditingStarted: (params: any) => {
                if (params.colDef.colId == 'Col1Freq') {
                    this.dataService.addClass(document.getElementById('mobpct'), 'no-overflow');
                }
            },
            onCellEditingStopped: (params: any) => {
                if (params.colDef.colId == 'Col1Freq') {
                    this.dataService.removeClass(document.getElementById('mobpct'), 'no-overflow');
                    if (!params.value && this.rowData2.length > 1) {
                        for (var i = params.rowIndex + 1; i < this.rowData2.length; i++)
                            this.selectedItems[i - 1] = this.selectedItems[i];
                        this.selectedItems[this.rowData2.length - 1] = null;
                        this.rowData2.splice(params.rowIndex, 1);
                        this.rowData2 = [].concat(this.rowData2);
                    }
                    else if (this.selectedItems[params.rowIndex] && params.value != this.selectedItems[params.rowIndex].name) {
                        this.selectedItems[params.rowIndex] = null;
                    }
                    else {
                        if (!params.data.quantity)
                            setTimeout(() => params.api.flashCells({ rowNodes: [params.node], columns: ['quantity'] }));
                        this.onFreqAdded();
                    }
                }
            },
        };

        this.frameworkComponents2 = { numericEditor: NumericEditor, dropdownEditor: DropdownEditor, dropdownRenderer: DropdownRenderer, typeaheadEditor: TypeaheadEditor };

        this.columnDefs2 = [
            {
                headerName: "Group Name*", field: "groupname", minWidth: 200, colId: "Col1", flex: 4,
                editable: true,
                cellRenderer: "dropdownRenderer",
                cellRendererParams: { options: [] },
                cellEditor: "dropdownEditor",
                cellEditorParams: { options: [] }
            },
            {
                headerName: "Quantity*", editable: true, cellEditor: "numericEditor", field: "quantity", flex: 1, maxWidth: 90, headerClass: 'leftHeader', cellClass: 'leftText', cellEditorParams: (par) => { return { value: par.value, focus: true }; }
            },
        //    { headerName: "Description", editable: true, cellEditor: "agTextCellEditor", field: "description", flex: 0.6, },
        //    { headerName: "Weight", editable: true, cellEditor: "numericEditor", field: "weight", type: "numericColumn", width: 90, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
        //    { headerName: "Value", editable: true, cellEditor: "numericEditor", field: "value", type: "numericColumn", width: 90, cellEditorParams: (par) => { return { value: par.value, focus: false }; } },
        ];

        this.columnDefs2Freq = [
            {
                headerName: "Item Name*", field: "groupname", editable: true, colId: "Col1Freq", flex: 3,
                cellClassRules: {
                    'no-hscode': (params) => { return params.value && (!this.selectedItems[params.rowIndex] || params.value != this.selectedItems[params.rowIndex].name) }
                },
                cellEditor: "typeaheadEditor",
                cellEditorParams: { focus: true, context: this }
            },
            { headerName: "Quantity*", editable: true, cellEditor: "numericEditor", field: "quantity", flex: 1, maxWidth: 90, headerClass: 'leftHeader', cellClass: 'leftText', cellEditorParams: (par) => { return { value: par.value, focus: true }; } },
        //    { headerName: "Description", editable: true, cellEditor: "agTextCellEditor", field: "description", flex: 0.6, },
        //    { headerName: "Weight", editable: true, cellEditor: "numericEditor", field: "weight", type: "numericColumn", width: 90, cellEditorParams: (par) => { return { value: par.value, focus: true }; } },
        //    { headerName: "Value", editable: true, cellEditor: "numericEditor", field: "value", type: "numericColumn", width: 90, cellEditorParams: (par) => { return { value: par.value, focus: true }; } },
        ];

        //this.rowData2 = [{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: 0 }];

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';

        //for (var n = 0; n <= 800; n += 50)
        //    this.insOpt.push({key: n.toString(), value: n.toFixed(2)});

        this.dataService.getItemGroups().subscribe((ui: any[]) => {
            this.itemGroups = ui;

            let dd = this.columnDefs2.find(x => x.field == 'groupname');
            dd.cellEditorParams.options = ui.map(o => { return { key: o.Value, value: o.Text } });

            dd.cellRendererParams.options = dd.cellEditorParams.options;
        });

        let dt = new Date();
        //dt.setDate(dt.getDate() - 1);
        let enddt = new Date();
        this.addBusinessDays(enddt, 6);
        this.dpOptions = {
            dateFormat: 'mmm dd, yyyy', disableWeekends: true, showTodayBtn: false, firstDayOfWeek: 'su', markCurrentDay: false, inline: true, sunHighlight: false,
            disableUntil: { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() }, disableSince: { year: enddt.getFullYear(), month: enddt.getMonth() + 1, day: enddt.getDate() }
        };
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            let t = params.get('type');
            this.shipType = t || 'I';
        });

        this.setupQuagga();

        this.form1 = this.fb.group({
            scountry: ['', Validators.required], soblast: ['', Validators.required], szip: ['', Validators.required], scity: ['', Validators.required],
            sstreetn: ['', Validators.required], sapartn: [''], sstreet: ['', Validators.required],

            sphone: ['', [Validators.required, Validators.pattern(/^[0-9]{4,10}$/)]], semail: ['', Validators.required], sfname: ['', Validators.required], slname: ['', Validators.required],
        });
        this.form2 = this.fb.group({
            rcountry: ['', Validators.required], roblast: ['', Validators.required], rzip: ['', Validators.required], rcity: ['', Validators.required],
            rstreetn: ['', Validators.required], rapartn: [''], rstreet: ['', Validators.required], rrayon: [''], 

            rphone: ['', [Validators.required, Validators.pattern(/^[0-9]{4,10}$/)]], remail: ['', Validators.required], rfname: ['', Validators.required], rlname: ['', Validators.required],
            passportS: ['', Validators.required], passportN: ['', Validators.required], passportDOB: ['', Validators.required],
        });
        this.form3 = this.fb.group({
            value: ['', [Validators.required, Validators.pattern(/^[0-9]+(.[0-9]?)?$/)]], insurance: ['0'], acceptAll: [false], acceptTerms: [false, Validators.requiredTrue], acceptInsurance: [false, Validators.requiredTrue], acceptVAT: [false, Validators.requiredTrue], acceptDims: [false, Validators.requiredTrue], addtl: [''], brokr: [''], discc: ['']
        });

        this.route.url.subscribe(url => {
            console.log(url);
            this.start();
        });
    }

    start() {
        this.UserBranch = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 2 : 1;
        this.dataService.agent_drop = false;
        if (this.dataService.userData.UserData.u_type != 'CUSTOMER') {
            this.agent_drop = false;
            //this.phase0 = false;
            this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.dataService.userData.UserData.branchID);
            //this.init();
        }
        else {
            if (this.dataService.userData.UserData["branchID"])
                this.UserBranch = this.dataService.userData.UserData["branchID"];
            this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.UserBranch);
        }
    }

    ngAfterViewInit() {
        const input = document.querySelector("#sphone");
        this.itiS = intlTelInput(input, {
            allowDropdown: true,
            initialCountry: "us",
            separateDialCode: true,
            preferredCountries: ["ca", "us"],
        });

        setTimeout(() => {
            this.inited = true;

            this.dataService.queue.subscribe({
                next: (v) => this.senderAddress(v)
            });
            this.dataService.queueDomestic.subscribe({
                next: (v) => this.domesticAddress(v)
            });
        })
    }

    addBusinessDays(currentDate: Date, numToAdd: number) {
        for (let i = 1; i <= numToAdd; i++) {
            currentDate.setDate(currentDate.getDate() + 1);
            if (currentDate.getDay() === 6) {
                currentDate.setDate(currentDate.getDate() + 2);
            }
            else if (currentDate.getDay() === 0) {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    }

    init() {
        this.pageno = -1;
        this.animationState = ['in', 'out', 'out', 'out', 'out', 'out'];
        this.txtOK = 'Next';
        this.pageTitle = 'Select Meest Drop-off Location';
        this.discountID = null;
        this.errorMessage = '';

        this.rowData2 = [{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: 0 }];
        this.selectedItems = [];
        this.post_agent = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 'MAI' : 'MEM';
        if (this.dataService.userData.Branch.id != 1 && this.estimator0.meestHub)
            this.post_agent = this.estimator0.meestHub;
        if (this.dataService.isGuest && this.closestAgent)
            this.post_agent = this.closestAgent.agentcode;

        zip(this.dataService.getCountryState(), this.dataService.getAgent(this.post_agent), this.dataService.getCountries(), this.dataService.getInsuranceAmount(), this.dataService.getDomesticCountry()).subscribe((res: any[]) => {
            this.countryStates = res[0];
            this.meestAgent = res[1];
            this.countries = res[2];
            this.countries.forEach(x => x["expressShip"] = this.expressShipCountries.some(c => c == x.CountryCodeValue));
            res[3].forEach(x => { var n = Number(x); this.insOpt.push({ key: n.toString(), value: n.toFixed(2) }) });
            var domesticCountry = res[4];

            const input = document.querySelector("#rphone");
            this.itiR = intlTelInput(input, {
                allowDropdown: true,
                initialCountry: this.shipType == 'D' ? domesticCountry.CountryCode2 : "ua",
                separateDialCode: true,
                onlyCountries: this.shipType == 'D' ? [] : this.countries.map(x => x.CountryCode2)
            });

            if (this.dataService.userData.UserData.u_email || this.dataService.isGuest) {
                let uc = this.dataService.userData.UserData.u_country.toUpperCase();
                this.itiS.setCountry(uc == 'USA' ? 'us' : 'ca');
                if (uc == 'CAN' || uc == 'CA') uc = 'CANADA';
                this.oblastOpt = this.countryStates[uc].map(c => {
                    return { key: c.state, value: c.desc }
                });
                if (this.dataService.userData.UserData.u_type == 'CUSTOMER') {
                    let model = {
                        scountry: this.dataService.userData.UserData.u_country.toUpperCase(),
                        szip: this.dataService.userData.UserData.u_index,
                        soblast: this.dataService.userData.UserData.u_oblast,
                        scity: this.dataService.userData.UserData.u_city,
                        sapartn: this.dataService.userData.UserData.u_apart,
                        sstreetn: this.dataService.userData.UserData.u_house,
                        sstreet: this.dataService.userData.UserData.u_street,
                    };
                    if (!this.dataService.isGuest)
                        model = Object.assign({
                            slname: this.dataService.userData.UserData.u_lastnm,
                            sfname: this.dataService.userData.UserData.u_firstnm,
                            sphone: this.dataService.userData.UserData.u_phone,
                            semail: this.dataService.userData.UserData.u_email,
                        }, model);

                    var cl = this.countryStates[uc];
                    if (cl) {
                        let v = this.dataService.userData.UserData.u_oblast.toUpperCase();
                        let sn = cl.find(x => x.state == v || x.desc.toUpperCase() == v);
                        if (sn)
                            model.soblast = sn.state;
                    }
                    this.form1.patchValue(model);
                }

                var rmodel = this.form2.value;
                if (this.page1.pickupAddress) {
                    this.page1.rcountrycode = this.page1.pickupAddress.CountryCode;
                    var rq = this.oblasts.find(x => x.r_region == this.page1.pickupAddress.Oblast);
                    if (rq)
                        this.page1.roblast = rq.Id;
                    //this.parcel.rcity = this.parcel.pickupAddress.City;
                    //this.parcel.rstreet = this.parcel.pickupAddress.Address;
                    //this.parcel.rstreetn = this.parcel.pickupAddress.House;

                    this.form2.get('rcity').disable({ onlySelf: true }); rmodel.rcity = this.page1.pickupAddress.City;
                    this.form2.get('rstreet').disable({ onlySelf: true }); rmodel.rstreet = this.page1.pickupAddress.Address;
                    this.form2.get('rstreetn').disable({ onlySelf: true }); rmodel.rstreetn = this.page1.pickupAddress.House;
                }

                this.form2.get('rcountry').disable({ onlySelf: true });
                if (this.shipType == 'D') {
                    this.oblasts = [{ Id: this.page1.roblast, r_region: this.page1.roblast }];
                    this.form2.get('roblast').disable({ onlySelf: true });
                    rmodel.roblast = this.page1.roblast;
                    rmodel.rcountry = this.page1.rcountry;
                    rmodel.rzip = this.estimator1.domestic.u_index;
                    rmodel.rcity = this.estimator1.domestic.u_city;
                    rmodel.rstreetn = this.estimator1.domestic.u_house;
                    rmodel.rstreet = this.estimator1.domestic.u_street;
                }
                else {
                    var cc = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode && x.CountryCodeType != 'DropDownTable');
                    if (cc) {
                        rmodel.rcountry = cc.r_country;
                        this.oblasts = [];
                        this.form2.get('roblast').setValidators(null);
                    }
                    else {
                        cc = this.oblasts.find(x => x.Id == this.page1.roblast);
                        if (cc) {
                            rmodel.rcountry = cc.r_country;
                            this.form2.get('roblast').disable({ onlySelf: true }); rmodel.roblast = cc.Id;
                        }
                    }
                }

                this.receivingCountry = this.shipType == 'D' ? domesticCountry : this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry);
                if (this.receivingCountry) {
                    this.receivingCountry.expressShip = this.express;
                    this.itiR.setCountry(this.receivingCountry.CountryCode2);
                }
                if (this.dataService.isGuest)
                    this.emailRequired = true;
                else {
                    if (this.receivingCountry && this.receivingCountry.ValidationRequired)
                        this.emailRequired = true;
                    else {
                        this.emailRequired = false;
                        this.form2.get('remail').setValidators(null);
                        //this.questions_receiver.find(x => x.key == 'remail').label += ' (optional)';
                    }
                }

                this.form2.patchValue(rmodel);

                if (this.passportReqd.indexOf(rmodel.rcountry) >= 0 || rmodel.rcountry === 'India') {
                    this.form2.get('passportDOB').setValidators([Validators.required]);
                    this.form2.get('passportS').setValidators([Validators.required]);
                    if (this.passportReqd.indexOf(rmodel.rcountry) >= 0)
                        this.form2.get('passportN').setValidators([Validators.required]);
                    else
                        this.form2.get('passportN').setValidators([Validators.required, , Validators.pattern(/^([0-9]{12}|[A-Z][0-9]{7})$/)]);
                }
                else {
                    this.form2.get('passportDOB').clearValidators();
                    this.form2.get('passportS').clearValidators();
                    this.form2.get('passportN').clearValidators();
                }
                this.form2.get('passportDOB').updateValueAndValidity();
                this.form2.get('passportS').updateValueAndValidity();
                this.form2.get('passportN').updateValueAndValidity();
            }

            setTimeout(() => $('#scountry').selectpicker());

            initAutocomplete_s(this.updateStateList.bind(this), 'g_autocomplete_s2');
            restrictCountry_s(['us', 'ca']);

            let agent_addrComp = {};
            let user_addrComp = {};
            let addrComp = this.shipType == 'D' ? this.dataService.parseUserAddress(agent_addrComp, this.estimator0.domestic) : this.dataService.parseAgentAddress(res[1], agent_addrComp);
            let addrComp_u = this.dataService.parseUserAddress(user_addrComp);
            this.pageno = 1;
            if (!this.agent_drop || (this.dataService.isGuest && this.closestAgent)) {
                this.submitted(JSON.stringify({ userAddress: user_addrComp, agentAddress: agent_addrComp, agent: res[1] }));  //  Skip the map page;
                this.accordionClicked(2, true);
            }
            else if (this.agent_drop)
                this.locateAgent();
        });
    }

    accordionClicked(ix: number, set?: boolean, repeated?: boolean ) {
        if (!this.accordionMenu[ix]) {
            if (set && ix == 4) {
                if (this.shipType == 'D' && this.form2.getRawValue().rphone.replace(/[()\s\-]/g, '').length != 10) {
                    this.phoneMessage = "Phone number has to have 10 digits";
                    return;
                }
                if (this.estimator1.expressShip && this.receivingCountry.expressShip && !repeated) {
                    setTimeout(() => this.getExpressRates());
                    return;
                }
            }
            if (set && ix > 0)
                this.accordionCompleted[ix - 1] = true;
            if (this.parcelModel && ix < 6)
                return;
            if (!this.parcelModel && ix == 6)
                return;
            if (!this.panelCompleted(ix))
                return;

            for (var i = 0; i < this.accordionMenu.length; i++)
                this.accordionMenu[i] = false;
            if (ix == 3 && this.dataService.isGuest && !this.verCodeInput) {
                this.errorMessage = '';
                this.verCodeError = false;
                this.verCode.show();
            }
            else if (ix == 4) {
                this.dataService.checkpoint(7).subscribe((ui: string) => {
                    if (this.estimator1.expressShip && this.receivingCountry.expressShip)
                        this.disclaimer.show();
                    else
                        this.hazModal.show();
                });
            }
            else
                this.accordionMenu[ix] = true;
            if (ix == 0)
                setTimeout(() => {
                    this.estimator0.estimating = false;
                    this.estimator0.setupCountryPicker();
                });
            //if (ix == 1) {
            //    this.estimator1.postRates();
            //}
            if (ix == 2) {
                setTimeout(() => {
                    this.form1.get('scountry').patchValue(this.dataService.userData.UserData.u_country.toUpperCase()); $('#scountry').selectpicker(); });
            }
            if (ix == 3) {
                initAutocomplete(this.updateStateListR.bind(this));
                var cc = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode);
                if (!cc && this.page1.roblast)
                    cc = this.countries.find(x => x.r_country == this.page1.rcountry);
                if (cc)
                    restrictCountry(cc.CountryCode2);
            }
            if (ix == 4) {
                //this.getExpressRates();
                this.BrokerageLimit = this.receivingCountry && this.receivingCountry.BrokerageLimit > 0 ? this.receivingCountry.BrokerageLimit : this.dataService.userData.Branch.BrokerageLimit;
                if (!this.receivingCountry || !this.receivingCountry.useVAT) {
                    this.form3.get('acceptVAT').patchValue(true);
                    this.form3.get('acceptVAT').disable();
                }
                else {
                    this.form3.get('acceptVAT').patchValue(false);
                    this.form3.get('acceptVAT').enable();
                }
            }
            if (ix == 5) {
                this.signature.init();
                this.pageno = 5;
                this.submitted('grid');
            }
            if (ix == 6) {
                if (this.dataService.isGuest)
                    this.useBillingAddress = true;
                if (this.dataService.agent_drop && this.dataService.userData.UserData.u_type == 'CUSTOMER') {
                    this.dataService.agent_drop = false;
                    this.payMethods = [{ Code: 'AGENT_DROPOFF', Method: "Pay at Agent's Location" }];
                    //if (this.dataService.isGuest)
                    //    this.payMethods.push({ Code: 'ONLINE_PAYMENT', Method: "Credit Card" });
                    this.payText = 'PRINT CONFIRMATION';
                    this.paycode = this.payMethods[0].Code;
                    //this.methodChange(null);
                }
                else
                    this.dataService.getPaymentMethods('PARCEL').subscribe((ui: any[]) => {
                        this.payMethods = ui;
                        if (ui.length > 0) {
                            //this.paycode = ui[0].Code;
                            this.payText = 'PAY';
                            //this.methodChange(null);
                        }
                    });
            }
        }
    }

    verSend() {
        this.dataService.verificationEmail(this.form1.controls[this.verHow == 'email' ? 'semail' : 'sphone'].value, this.verHow).subscribe((ui: any) => {
            this.verCodeSent = ui;
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.verCode.hide();
                this.accordionMenu[2] = true;
            });
    }

    onCodeCompleted(ev) {
        if (ev === this.verCodeSent) {
            this.verCode.hide();
            this.verCodeInput = ev;
            this.accordionClicked(3, true);
        }
        else
            this.verCodeError = true;
    }

    switchPopups() {
        this.disclaimer.hide();
        this.hazModal.show();
    }

    panelCompleted(ix: number) {
        if (ix == 0)
            return true;
        else
            return this.accordionCompleted.slice(0, ix).every(x => x == true);
    }

    senderAddress(ev) {
        if (ev.country) {
            this.estimator0.countryFrom = ev.country;
            if (!this.estimator0.meestHub && this.estimator0.UserBranch == 1)
                this.estimator0.UserBranch = ev.country == 'USA' ? 2 : 1;
            }
        else {
            var addrComp = this.dataService.parseUserAddress(this.estimator0.user_addrComp);
            this.estimator0.location = ev.location;
            this.estimator0.agentRates = true;
            this.estimator0.showAgentRates();
        }
    }

    domesticAddress(ev) {
        if (ev.country) {
            this.estimator0.country = ev.country;
            this.estimator0.oblast = ev.stateprov;
        }
        else {
            this.estimator0.domestic = ev;
        }
    }

    getExpressRates() {
        if (this.estimator1.expressShip && this.receivingCountry.expressShip) {
            this.page3 = Object.assign(this.form1.value, this.form2.getRawValue());

            let agent_addrComp: any = { postal_code: '', locality: '', state: '', country: 'US' };
            let user_addrComp = {};
            let addrComp = this.dataService.parseAgentAddress(this.meestAgent, agent_addrComp);

            let fromAddr = { City: agent_addrComp.locality, Province: agent_addrComp.state, Country: agent_addrComp.country.toUpperCase() == 'USA' ? 'US' : 'CA', Postal: agent_addrComp.postal_code, Address: agent_addrComp.address };
            let toAddr = {
                City: this.page3.rcity, Province: this.page3.roblast, Country: this.receivingCountry.CountryCode2, Postal: this.page3.rzip,
                Address: this.page3.rstreetn + ' ' + this.page3.rstreet };
            if (this.page3.rapartn)
                toAddr.Address = `${this.page3.rapartn}-` + toAddr.Address;
            let param = {
                From: fromAddr, To: toAddr, Weight: this.page1.weight, Height: this.page1.h, Width: this.page1.w, Length: this.page1.l
            };
            this.errorMessage = '';
            zip(this.dataService.getExpressPostRates(param), this.dataService.getFixedRates()).subscribe((res: any) => {
                var ui = res[0];
                if (ui.Error) {
                    this.errorMessage = ui.Message;
                }
                else {
                    var cfr = 0;
                    var exfr = res[1].find(x => x.carrier == 'ups_worldwide_expedited');
                    if (exfr) cfr = (exfr.ValueFixed == null ? 0 : exfr.ValueFixed);

                    var r = ui.Rates[0];
                    this.page2.carrier -= (this.page2.fixed ? this.page2.fixed : 0);
                    this.page2.total_rate_fee = r.Price + this.page2.carrier + cfr;  //  Fixed rate $15 for express shipping
                    this.page2.rate = r.Price + cfr;
                    this.page2.rate_fee_meest = r.Price + cfr;
                    this.page2.delivery = 0;
                    this.page2.delivery_meest = 0;
                    this.page2.RateName = 'Expedited';
                    this.page2.AirSea = 'Express';
                    this.page2.DeliveryModeCode = 'E';
                    this.page2.DeliveryModeDescription = 'Expedited';
                    this.accordionClicked(4, true, true);
                }
            },
                (err: any) => {
                    this.errorMessage = this.dataService.processError(err);
                });
        }
    }

    estimatorSubmitted() {
        if (!this.estimator0.errorMessage) setTimeout(() => {
            this.page1 = this.estimator0.getFormData();
            this.estimator1.page1 = this.page1;
            this.estimator1.countries = this.estimator0.countries;
            this.estimator1.country = this.estimator0.country;
            this.estimator1.oblast = this.estimator0.oblast;
            this.estimator1.oblasts = this.estimator0.oblasts;
            this.estimator1.deliverySelection = this.estimator0.deliverySelection;
            this.estimator1.deliverySelectionUPS = this.estimator0.deliverySelectionUPS;
            this.estimator1.deliverySelectionUSPS = this.estimator0.deliverySelectionUSPS;
            this.estimator1.deliverySelectionUPSPickup = this.estimator0.deliverySelectionUPSPickup;
            this.estimator1.deliverySelectionAgent = this.estimator0.deliverySelectionAgent;
            this.estimator1.post_rates = this.estimator0.post_rates;
            this.estimator1.domestic = this.estimator0.domestic;
            this.estimator1.shipType = this.estimator0.shipType;
            this.estimator1.meestHub = this.estimator0.meestHub;
            this.estimator1.UserBranch = this.estimator0.UserBranch;
            this.estimator1.receivingCountry = this.estimator0.receivingCountry;
            this.estimator1.expressFixedRate = this.estimator0.expressFixedRate;
            this.estimator1.pageno = 2;
            this.estimator1.estimated = true;
            this.estimator0.pageno = 1;
            setTimeout(() => this.accordionClicked(1, true));
        });
    }

    selectDrop(par: any) {
        var sel = 0;
        if (typeof par === 'object' && par !== null) {
            sel = par.agix;
            this.preselectedRate = par.rate;
        }
        else
            sel = Number(par);

        if (sel > 0 && !this.estimator1.checkWeight())
            return;

        if (sel == 2)   //  Agent drop-off
            this.agent_drop = true;
        else
            this.agent_drop = false;
        this.phase0 = false;
        this.dataService.agent_drop = this.agent_drop;
        let sc = '';

        if (sel > 0) {
            this.oblasts = this.estimator1.oblasts;

            this.page2 = JSON.parse(par.rate);
            this.pickupAddress = this.page2.pickup;
            this.express = this.page2.express;
            sc = this.page2.post.ServiceCode;
            this.page2 = this.page2.delivery;
            this.page1["pickupAddress"] = this.pickupAddress;
        }

        if (this.dataService.isGuest)
            this.closestAgent = this.estimator1.closestAgent;

        //this.accordionClicked(2, true);
        if (sc === 'CanPar' || (sc == 'ups_ground' && this.page2.pickup)) {
            let dt = new Date();
            dt.setDate(dt.getDate() + 1);
            this.pickupWindow = { jsdate: dt };
            this.pickupDialog.show();
            //setTimeout(() => this.dpicker.openBtnClicked());
        }
        else {
            this.pickupWindow = null;
            setTimeout(() => this.init());
        }
    }

    setPickup() {
        this.pickupDialog.hide();
        setTimeout(() => this.init());
    }

    currencyFormatter(params) {
        return params.value ? params.value.toFixed(2) : '';
        //return "\xA3" + formatNumber(params.value);
    }

    onCountryChange() {
        if (this.countryStates[this.form1.get('scountry').value]) {
            this.oblastOpt = this.countryStates[this.form1.get('scountry').value].map(c => {
                return { key: c.state, value: c.desc }
            });
            this.form1.get('soblast').patchValue('');
        }
    }

    onInsuranceChange() {
        if (this.form3.get('insurance').value == '0') {
            this.form3.get('acceptInsurance').patchValue(false);
            this.form3.get('acceptInsurance').enable();
        }
        else {
            this.form3.get('acceptInsurance').patchValue(true);
            this.form3.get('acceptInsurance').disable();
        }
    }

    acceptAll(ev) {
        var b = ev.target.checked ? true : false;
        this.form3.get('acceptTerms').patchValue(b);
        this.form3.get('acceptInsurance').patchValue(b);
        this.form3.get('acceptDims').patchValue(b);
        if (this.receivingCountry && this.receivingCountry.useVAT) 
            this.form3.get('acceptVAT').patchValue(b);
    }

    onGrid2Ready(params) {
        this.grid2Api = params.api;
        this.grid2ColumnApi = params.columnApi;

        this.grid2Api.setRowData(this.rowData2);

        //params.api.sizeColumnsToFit();
        //this.autoSizeAll(params.columnApi);

    //    this.grid2Api.startEditingCell({
    //        rowIndex: 0,
    //        colKey: 'Col1'
    //    });
    }

    onfirstDataRendered(params) {
        //setTimeout(() => { params.api.sizeColumnsToFit() });
        //setTimeout(() => this.autoSizeAll(params.columnApi));
        //params.api.sizeColumnsToFit();
        //params.columnApi.autoSizeColumns(['Col1']);
    }

    autoSizeAll(colapi: any) {
        var allColumnIds = [];
        colapi.getAllColumns().forEach(column => {
            allColumnIds.push(column.colId);
        });
        colapi.autoSizeColumns(allColumnIds);
    }


    oninputChange(e: any) {
        var item = e.target;
        var good = item.value.match(/^[0-9]{4,10}$/i);
        this.phoneValidation[item.id] = good ? null : 'bad';
        if (item.id == 'sphone') {
            this.dataService.getPhoneSender(item.value).subscribe((addr: any) => {
                if (addr) {
                    this.form1.controls['sphone'].setValue(addr.s_phone);
                    this.form1.controls['semail'].setValue(addr.s_email);
                    this.form1.controls['sfname'].setValue(addr.s_firstnm);
                    this.form1.controls['slname'].setValue(addr.s_lastnm);
                    if (!this.dataService.isGuest) {
                        this.form1.controls['sapartn'].setValue(addr.s_apart);
                            this.form1.controls['scity'].setValue(addr.s_city);
                            this.form1.controls['scountry'].setValue(addr.s_country);
                            this.form1.controls['sstreetn'].setValue(addr.s_house);
                            this.form1.controls['szip'].setValue(addr.s_index);
                        this.form1.controls['soblast'].setValue(addr.s_oblast);
                            this.form1.controls['sstreet'].setValue(addr.s_street);

                            var sc = this.form1.controls['scountry'].value;
                            if (this.countryStates[sc.toUpperCase()]) {
                                this.oblastOpt = this.countryStates[this.form1.get('scountry').value].map(c => {
                                    return { key: c.state, value: c.desc }
                                });
                                this.form1.get('soblast').patchValue('');
                            }
                    }
                }
            });
        }
        else if (item.id == 'rphone' && item.value && item.value.length >= 10) {
            this.dataService.getPhoneRecipient(item.value).subscribe((addr: any) => {
                if (addr) {
                    this.form2.controls['rphone'].setValue(addr.r_phone);
                    this.form2.controls['remail'].setValue(addr.r_email);
                    this.form2.controls['rfname'].setValue(addr.r_firstnm);
                    this.form2.controls['rlname'].setValue(addr.r_lastnm);
                    if (!this.page1.pickupAddress) {
                        this.form2.controls['rzip'].setValue(addr.r_index);
                        this.form2.controls['rapartn'].setValue(addr.r_apart);
                        this.form2.controls['rcity'].setValue(addr.r_city);
                        this.form2.controls['rstreetn'].setValue(addr.r_house);
                        this.form2.controls['rrayon'].setValue(addr.r_region);
                        this.form2.controls['rstreet'].setValue(addr.r_street);
                    }
                }
            });
        }
    }

    paySelected(code: string) {
        this.paycode = code;
        document.getElementById(`paymet_${code}`).click();
        this.showPayment = (this.paycode == 'ONLINE_PAYMENT');
    }

    doDetails() {
        setTimeout(() => $(".modile-delivery-price .accordion .text").slideToggle(400));
    }

    onDiscount(id) {
        if (id > 0)
            this.discountID = id;
        this.submitted('grid_d');
    }

    submitted(payLoad: string) {
        if (payLoad && payLoad !== '') {

            if (this.pageno == 1) {
                if (this.dataService.userData.UserData.u_type == 'CUSTOMER') {
                    this.pageMap = JSON.parse(payLoad);
                    this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.pageMap.agent.branch);
                }
                else {
                    this.pageMap = null;
                    this.agent_drop = false;
                    this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.dataService.userData.UserData.branchID);
                }

                this.currencyId = this.dataService.userData.Branch.currency == 'CAD' ? 'Canadian dollars' : 'US dollars';
                this.pageno++;
            }

            if (this.pageno == 2) {
                //this.page1 = JSON.parse(payLoad);
                if (this.pageMap && this.agent_drop) {
                    if (this.pageMap.agent.agentcode == 'MEEST')
                        this.pageMap.agent.agentcode = 'MOREG';

                    this.page1["agentCode"] = this.pageMap.agent.agentcode;
                }

                //var cntr = this.countries.find(x => x.CountryCodeValue == this.page1.rcountry);

                //if (this.page1.roblast && this.oblasts.length > 0)   //  roblast contains the Id value
                //  this.page1["rcountrycode"] = this.oblasts.find(x => x.Id == this.page1.roblast).CountryCodeValue;
                //else {
                //  this.page1["rcountrycode"] = this.page1.rcountry;
                //  this.page1.rcountry = cntr.r_country;
                //}

                if (this.preselectedRate) {
                    this.pageno = 3;
                    payLoad = this.preselectedRate;
                    this.deliverySelection = [];
                }
                else
                    this.dataService.getDeliverySelection(this.page1).subscribe((ui: any[]) => {
                        if (ui.length > 0 && ui[0].RateName == 'Error')
                            this.errorMessage = ui[0].DeliveryModeDescription;
                        else {
                            this.errorMessage = '';
                            this.deliverySelection = ui;
                            this.pageTitle = 'Select Transport and Delivery';
                            this.nextPage();
                        }
                    });
            }

            if (this.pageno == 3) {
                this.page2 = JSON.parse(payLoad);  // this.gridApi.getSelectedRows()[0];
                if (this.pageMap)
                    this.pageMap.rate = this.page2.post;
                this.pickupAddress = this.page2.pickup;
                let express = this.page2.express;
                this.page2 = this.page2.delivery;
                this.page1["pickupAddress"] = this.pickupAddress;
                if (express)
                    this.page2.total_rate_fee += 15;

                this.pageTitle = 'Enter Address';
                this.nextPage();
            }

            if (this.pageno == 4) {
                this.page3 = JSON.parse(payLoad);
                //this.pageno++;

                //this.pageTitle = 'Select Discount';  //  Remove discount
                //this.nextPage();

                //    this.pageTitle = 'Select Agent'; 
                //    this.nextPage();
                //}

                this.errorMessage = '';
                this.questions = [];
                this.animationState[4] = 'out';

                //this.receivingCountry = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry);

                this.dataService.checkpoint(7).subscribe((ui: string) => {
                    this.hazModal.show();
                });

                //this.freqButtonState = 0;
                //setTimeout(() => this.freqButton(true));  //  Initial state - frequent items

                //this.pageTitle = 'Declare Parcel Content & Quantity';
                //this.nextPage();
            }

            if (this.pageno == 5) {
            //    for (var ix = this.rowData2.length - 1; ix >= 0; ix--) {
            //        if (this.rowData2[ix].groupname == '' || this.rowData2[ix].quantity == '')
            //            this.rowData2.splice(ix, 1);
            //    }
            //    this.page5 = this.rowData2;
            //    this.dataService.checkpoint(8).subscribe((ui: string) => {
            //        this.autoSizeAll(this.grid2ColumnApi);

            //        let cntr = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry);
            //        this.BrokerageLimit = cntr && cntr.BrokerageLimit > 0 ? cntr.BrokerageLimit : this.dataService.userData.Branch.BrokerageLimit;
            //        if (this.BrokerageLimit > 0 && this.BrokerageLimit < this.declaredValue)
            //            this.brokLimit.show();
            //    });
            }

            if (this.pageno == 6) {
                this.page4 = JSON.parse(payLoad);
                this.dataService.checkBarcode(this.page4.declaration).subscribe((ui: string) => {
                    if (ui && ui != '')
                        this.errorMessage = ui;
                    else {
                        this.dataService.checkpoint(9).subscribe((ui: string) => {
                            this.showSignature = true;
                            //this.completeParcel();
                        });
                    }
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                    });
            }
        }
        else {
            this.dataService.agent_drop = false;
            this.pickupAddress = null;
            if (this.page1) this.page1["pickupAddress"] = null;
            this.questions = [];
            this.animationState[0] = 'out';
            this.animationState[1] = 'out';
            this.animationState[2] = 'out';
            this.animationState[3] = 'out';
            this.animationState[4] = 'out';
            this.router.navigate(["home"]);
        }
    }

    signed(sig: any) {
        this.sigdata = sig;
        if (sig)
            this.parcelModel = {};
        else
            setTimeout(() => this.signature.init());
    }

    onSigned() {
        this.showSignature = false;
        this.completeParcel(this.sigdata);
    }

    startPayment() {
        if (this.isPaid)
            this.payment.createCPShipment();
        else if (this.paycode !== 'ONLINE_PAYMENT' || this.showPayment) {
            if (this.useBillingAddress)
                this.payment.billAddress = this.baComp.getBillAddress();
            this.payment.doPayment();
        }
        else {
            this.showPayment = true;
            if (this.payText == 'TRY AGAIN') {
                this.payText = 'PAY';
                this.payment.cancelOK();
            }
        }
    }

    onPaid(reason: string) {
        if (reason == 'label') {
            this.payText = 'PRINT LABEL';
            this.isPaid = true;
            if (this.dataService.userData.Branch.id) {
                this.dataService.paidSMS(this.page3.sphone, this.parcelModel.id).subscribe((ui: any) => {
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                    });
            }
        }
        else {
            this.payText = 'TRY AGAIN';
            this.showPayment = false;
        }
    }

    confirmClosed() {
        this.page3 = Object.assign(this.form1.value, this.form2.getRawValue());
        if (this.page3.passportDOB)
            this.page3.passportDOB = moment(this.page3.passportDOB).format('YYYY-MM-DD');

        if (this.shipType != 'D') {
            this.pageTitle = 'Declare Parcel Content & Quantities';
            this.accordionMenu[4] = true;

            this.freqButtonState = 0;
            setTimeout(() => this.freqButton(true));  //  Initial state - frequent items
        }
        else {
            this.page4 = { brokfee: 0, pakfee: 0, insamt: '0', estval: '0', notes: '', discc: '' };
            this.page5 = [];
            let model = this.buildModel(this.page1, this.page2, this.page3, this.page4, this.page5);
            this.dataService.checkpoint(8).subscribe((ui: string) => {
                this.accordionClicked(5, true);
            });
        }
    }

    setParcelContent() {
        let opt = this.form3.value;
        this.page4 = { brokfee: 0, pakfee: 0, insamt: opt.insurance, estval: opt.value, notes: opt.addtl, discc: opt.discc };
        if (opt.brokr) {
            let b = Number(opt.brokr);
            if (!isNaN(b)) this.page4.brokfee = b;
        }
        if (opt.value == 0) {
            this.errorMessage = 'Declared Value must be greater than zero';
            return;
        }
        this.declaredValue = opt.value;
        for (var ix = this.rowData2.length - 1; ix >= 0; ix--) {
            if (this.rowData2[ix].groupname == '' || this.rowData2[ix].quantity == '' || !this.selectedItems[ix] || this.selectedItems[ix].name != this.rowData2[ix].groupname)
                this.rowData2.splice(ix, 1);
            else
                this.rowData2[ix]["hsCode"] = this.selectedItems[ix].hsCode;
        }
        //model.value = opt.value;
        //model.weight = p1.weight;
        var totW = Number(this.page1.weight);
        var pW = Math.round(totW / this.rowData2.length * 100) / 100;
        var lW = totW - pW * (this.rowData2.length - 1);

        var totV = Number(opt.value);
        var pV = Math.round(totV / this.rowData2.length * 100) / 100;
        var lV = totV - pV * (this.rowData2.length - 1);

        for (var i = 0; i < this.rowData2.length - 1; i++) {
            this.rowData2[i]["weight"] = pW;
            this.rowData2[i]["value"] = pV;
        }
        this.rowData2[this.rowData2.length - 1]["weight"] = lW;
        this.rowData2[this.rowData2.length - 1]["value"] = lV;

        this.page5 = this.rowData2;

        this.errorMessage = '';
        this.discError = '';
        let model = this.buildModel(this.page1, this.page2, this.page3, this.page4, this.page5);
        this.dataService.getDiscountAmount(model).subscribe((d: number) => {
            if (d)
                this.discountValue = d;

            let cntr = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry);
            this.BrokerageLimit = cntr && cntr.BrokerageLimit > 0 ? cntr.BrokerageLimit : this.dataService.userData.Branch.BrokerageLimit;
            if (this.BrokerageLimit > 0 && this.BrokerageLimit < this.declaredValue)
                this.brokLimit.show();
            else
                this.dataService.checkpoint(8).subscribe((ui: string) => {
                    this.autoSizeAll(this.grid2ColumnApi);
                    this.accordionClicked(5, true);
                });
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.discError = this.dataService.processError(err);
                this.discountValue = 0;
            });
    }

    brokChange(isChange: boolean) {
        this.brokLimit.hide();
        if (!isChange)
            this.dataService.checkpoint(8).subscribe((ui: string) => {
                this.autoSizeAll(this.grid2ColumnApi);
                this.accordionClicked(5, true);
            });
    }

    private nextPage() {
        var page = this.pageno - 1;
        setTimeout(() => {
            this.pageno++;
            setTimeout(() => {
                this.animationState[page] = this.animationState[page] === 'out' ? 'in' : 'out';
                this.animationState[page + 1] = this.animationState[page + 1] === 'out' ? 'in' : 'out';
                $('html, body').animate({ scrollTop: '0px' }, 300);

                if (this.pageno == 6) {
                    let me = this;
                    $('#scanButton').off('click').on('click', function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        //$('#declaration').focus();
                        $('#declaration').val('');
                        me.scannedData = '';

                        me.showScanner = !me.showScanner;
                        if (me.showScanner)
                            setTimeout(() => me.startQuagga());
                        else
                            Quagga.stop();

                        return false;
                    });
                }
            });
        });
    }

    private completeParcel(signature: any) {
        let model = this.buildModel(this.page1, this.page2, this.page3, this.page4, this.page5);
        let isCarrier = this.pageMap && this.pageMap.rate.ServiceName != 'Drop-off' && !this.agent_drop;

        this.errorMessage = '';
        this.saving = true;
        this.dataService.saveParcel(model, isCarrier).subscribe((ui: number) => {
            model.id = ui;
            this.page1['id'] = ui;

            let mitem = this.page5.filter(x => x.groupname != '' && x.quantity != '').map(it => {
                var gr;
                if (this.freqButtonState == 1)
                    gr = { Text: it.groupname, hs_code: it.hsCode };  //this.itemGroups.find(x => x.Text == it.groupname);
                else
                    gr = this.itemGroups.find(x => x.Value == it.groupname);
                if (!gr)
                    gr = { Text: it.groupname };

                return {
                    id: model.id, groupname_e: gr.Text, description: it.description, weight: it.weight, value: it.value, quantity: it.quantity,
                    branchcurrency: this.dataService.userData.Branch.currency, branchweight: this.dataService.userData.Branch.weight,
                    hs_code: gr.hs_code, useremail: this.dataService.userData.UserData.u_email
                }
            });

            concat(...[this.dataService.saveParcelItem(mitem), this.dataService.saveSignature(model.id, signature)].map(s => s.pipe(last()))).pipe(toArray()).subscribe((ui2: any[]) => {
                this.parcelModel = model;
                if (isCarrier) {
                    this.createCPShipment(model.id).subscribe((ui: any) => {
                        this.saving = false;
                        //this.router.navigate(["payment", model.id, 0]);
                        this.accordionClicked(6, true);
                    },
                        (err: any) => {
                            this.saving = false;
                            this.errorMessage = this.dataService.processError(err);
                        });
                }
                else if (this.pageMap && this.agent_drop) {
                    this.dataService.saveParcelAgent(model.id, this.pageMap.agent.agentcode).subscribe((ui: any) => {
                        this.saving = false;
                        //this.router.navigate(["payment", model.id, 0]);
                        this.accordionClicked(6, true);
                    },
                        (err: any) => {
                            this.saving = false;
                            this.errorMessage = this.dataService.processError(err);
                        });
                }
                else
                    this.accordionClicked(6, true);
                    //this.router.navigate(["payment", model.id, 0]);
            },
                (err: any) => {
                    this.saving = false;
                    this.errorMessage = this.dataService.processError(err);
                });
        },
            (err: any) => {
                this.saving = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    updateStateList(country, stateprov, elems) {
        if (elems) {
            elems.forEach(x => this.form1.controls[x.key].patchValue(x.value));
        }
        else {
            if (!this.countryStates[country.toUpperCase()])
                return false;

            //this.form1.get('scountry').patchValue(country.toUpperCase());
            //this.onCountryChange();
        }
        return false;
    }

    updateStateListR(country, stateprov, elems) {
        if (elems) {
            var cc = this.countries.find(x => x.r_country == this.page1.rcountry && x.CountryCodeType == 'DropDownTable');
            elems.forEach(x => {
                if (!cc || x.key != 'roblast')
                    this.form2.controls[x.key].patchValue(x.value);
            });
        }
        else {
            let cntr = this.countries.find(x => x.CountryCodeValue == country || x.r_country == country);

            //this.form2.get('rcountry').patchValue(country.toUpperCase());
            //this.onCountryChange();
        }
        return false;
    }

    showHistory() {
        this.dataService.senderHistory().subscribe((ui: any[]) => {
            this.histList = ui;
            this.histList.forEach(x => x["selected"] = false);
            this.history.show();
        });
    }
    pickHistory(ix: number) {
        this.histList.forEach(x => x["selected"] = false);
        this.histList[ix].selected = true;
    }
    selHistory() {
        var sel = this.histList.find(x => x.selected);
        this.history.hide();
        if (sel) {
            this.form2.controls['rphone'].setValue(sel.r_phone);
            this.form2.controls['remail'].setValue(sel.r_email);
            this.form2.controls['rfname'].setValue(sel.r_firstnm);
            this.form2.controls['rlname'].setValue(sel.r_lastnm);
            if (!this.page1.pickupAddress) {
                this.form2.controls['rzip'].setValue(sel.r_index);
                this.form2.controls['rapartn'].setValue(sel.r_apart);
                this.form2.controls['rcity'].setValue(sel.r_city);
                this.form2.controls['rstreetn'].setValue(sel.r_house);
                this.form2.controls['rrayon'].setValue(sel.r_region);
                this.form2.controls['rstreet'].setValue(sel.r_street);
            }
        }
    }

    buildModel(p1: any, rates: any, address: any, p4: any, p5: any): Parcel {
        let model = new Parcel();
        model.id = 0;

        model.userID = this.dataService.userData.UserData.id;
        model.branchID = this.dataService.userData.Branch.id;
        model.source = this.dataService.userData.UserData.u_email;
        model.customerCD = this.dataService.userData.UserData.customerCD;

        model.DeliveryMode = rates.DeliveryModeCode;
        model.s_a_e = rates.AirSea;
        model.vol_fee = rates.volume_fee;
        model.vol_wght = rates.VolumeWeight;
        model.rate = rates.rate;
        model.rate_fee = rates.total_rate_fee;  //rates.rate_fee;
        model.delivery = rates.delivery;
        model.rate_fee_meest = rates.rate_fee_meest;
        model.rate_fee_agent = rates.rate_fee_agent;
        model.rate_fee_ws = rates.rate_fee_ws;
        model.delivery_meest = rates.delivery_meest;
        model.delivery_agent = rates.delivery_agent;
        model.delivery_ws = rates.delivery_ws;
        model.ins_meest = rates.ins_meest;
        model.ins_agent = rates.ins_agent;
        model.ins_ws = rates.ins_ws;
        model.Packaging_meest = rates.Packaging_meest;
        model.Packaging_agent = rates.Packaging_agent;
        model.Packaging_ws = rates.Packaging_ws;
        model.RateName = rates.RateName;
        model.carrier = rates.carrier;
        model.pickup = rates.pickup;

        model.s_phone = address.sphone.replace(/\D+/g, '');
        model.s_email = address.semail;
        model.s_apart = address.sapartn;
        model.s_city = address.scity;
        model.s_country = address.scountry;
        model.s_firstnm = address.sfname;
        model.s_house = address.sstreetn;
        model.s_index = address.szip;
        model.s_oblast = address.soblast;
        model.s_lastnm = address.slname;
        model.s_region = address.sregion;
        model.s_street = address.sstreet;

        model.r_phone = address.rphone.replace(/\D+/g, '');
        model.r_email = address.remail;
        model.r_apart = address.rapartn;
        model.r_city = address.rcity;
        model.r_country = address.rcountry;
        model.r_CountryCode = p1.rcountrycode;
        model.r_firstnm = address.rfname;
        model.r_house = address.rstreetn;
        model.r_index = address.rzip;
        model.r_oblast = address.roblast;
        model.r_lastnm = address.rlname;
        model.r_region = address.rregion;
        model.r_street = address.rstreet;

        model.customs = p4.brokfee;
        model.packaging = p4.pakfee;
        model.ins_amount = p4.insamt;
        model.value = p4.estval;
        model.weight = p1.weight;
        model.vol_H = p1.h;
        model.vol_L = p1.l;
        model.vol_W = p1.w;
        model.notes = p4.notes;
        model.code_a = this.shipType == 'D' ? 'Domestic' : ((!p4.declaration || p4.declaration == '') ? null : p4.declaration);
        model.Discount_00 = p4.discc;
        model.DiscountID = this.discountID;

        if (address.passportDOB)
            model.skid = address.passportDOB + '~~' + address.passportS + '~~' + address.passportN;

        return model;
    }

    freqButton(bInit: boolean) {
        this.rowData2 = this.rowData2.filter(x => x.groupname != '' && x.quantity != '');
        this.rowData2.forEach(it => {
            let gr = this.itemGroups.find(x => x.Value == it.groupname);
            if (gr)
                it.groupname = gr.Text;
        });
        this.freqButtonState = 1 - this.freqButtonState;
        if (this.freqButtonState == 1) {
            this.dataService.getItemGroupsFrequent().subscribe((ui: any[]) => {
                this.rowData2 = this.rowData2.concat(ui.map(it => { return { Id: it.ID, groupname: it.Descr, description: '', Weight: '', value: '', quantity: '' }; }));
                for (var ix = this.rowData2.length - 1; ix >= 0; ix--) {
                    if (this.rowData2.filter(x => x.groupname == this.rowData2[ix].groupname).length > 1)
                        this.rowData2.splice(ix, 1);
                }

                if (!bInit)
                    this.grid2Api.setColumnDefs(this.columnDefs2Freq);

                this.grid2Api.setRowData(this.rowData2);

            //    if (bInit)
            //        setTimeout(() => this.grid2Api.startEditingCell({ rowIndex: 0, colKey: 'Col1Freq' }));
            });
        }
        else {
            this.rowData2.forEach(it => {
                let gr = this.itemGroups.find(x => x.Text == it.groupname);
                if (gr)
                    it.groupname = gr.Value;
            });
            if (this.rowData2.length == 0) {
                this.rowData2 = [{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: 0 }];
                setTimeout(() => {
                    this.grid2Api.startEditingCell({ rowIndex: 0, colKey: 'Col1' });
                });
            }

            this.grid2Api.setColumnDefs(this.columnDefs2);
            this.grid2Api.setRowData(this.rowData2);
        }
    }

    addRow() {
        this.rowData2 = this.rowData2.concat([{ groupname: '', description: '', weight: '', value: '', quantity: '', seqno: this.rowData2.length }]);

        this.grid2Api.setRowData(this.rowData2);
    }

    deleteRow() {
        var row = this.grid2Api.getSelectedRows()[0];
        if (!row)
            return;
        var ix = row["seqno"];

        this.rowData2.splice(ix, 1);
        for (var i = 0; i < this.rowData2.length; i++)
            this.rowData2[i]["seqno"] = i;

        this.grid2Api.setRowData(this.rowData2);
    }

    get hasRows() {
        if (this.shipType == 'D')
            return true;

        var b = this.rowData2.length > 0 && this.rowData2.filter(x => x.quantity).length > 0 &&
            this.rowData2.filter((x, ix) => x.groupname || x.quantity).every(x => x.groupname !== '' && x.quantity != '' && Number(x.quantity) > 0);
            //this.rowData2.filter((x, ix) => x.quantity).every(x => x.groupname !== '' && x.quantity != '' && Number(x.quantity) > 0);
            //this.rowData2.filter((x, ix) => x.quantity).every(x => x.groupname !== '' && x.quantity != '' && (!this.receivingCountry.ValidationRequired || (x.weight && x.value)));
        if (b)
            for (var i = 0; i < this.rowData2.length; i++) {
                var x = this.rowData2[i];
                if (x.groupname && (!this.selectedItems[i] || this.selectedItems[i].name != x.groupname))
                    b = false;
            }
        return b;
    }

    onFreqAdded() {
        if (!this.rowData2.find(x => !x.groupname && !x.quantity))
            this.addRow();
    }

    locateAgent() {
        var sub = this.modalService.onHidden.subscribe((reason: string) => {
            sub.unsubscribe();

            if (this.dataService.userData.UserData.u_type == 'CUSTOMER') {
                var data = this.bsModalRef.content.getData;
                if (!data)
                    return;
                this.pageMap = JSON.parse(data);
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.pageMap.agent.branch);
            }
            else {
                this.pageMap = null;
                this.agent_drop = false;
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.dataService.userData.UserData.branchID);
            }

            this.currencyId = this.dataService.userData.Branch.currency == 'CAD' ? 'Canadian dollars' : 'US dollars';
            this.pageno = 2;
            this.submitted('map');
            this.accordionClicked(2, true);
        })

        if (this.dataService.userData.UserData.customerCD != 'OOREG') {
            //this.pageMap = null;
            //this.agent_drop = false;

            this.currencyId = this.dataService.userData.Branch.currency == 'CAD' ? 'Canadian dollars' : 'US dollars';
            this.pageno = 2;
            this.submitted('map');
            this.accordionClicked(2, true);
            return;
        }
        let address = buildSenderAddress();
        if (address != '') {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': address }, (results, status) => {
                if (status == 'OK') {
                    const config = {
                        ignoreBackdropClick: true,
                        class: 'modal-lg'
                    };
                    this.bsModalRef = this.modalService.show(ModalMapComponent, config);
                    this.bsModalRef.content.country = getAddressComponent(results[0].address_components, 'country');
                    this.bsModalRef.content.location = results[0].geometry.location;
                    this.bsModalRef.content.cancelled = false;
                    this.bsModalRef.content.show();
                    //map.setCenter(results[0].geometry.location);
                    //var marker = new google.maps.Marker({
                    //    map: map,
                    //    position: results[0].geometry.location
                    //});
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    }

    carMove(ev, dir) {
        ev.preventDefault();
        $('#myCarousel').carousel(dir);
        return false;
    }

    createCPShipment(shipid: number) {
        this.errorMessage = "";
        let agentCountry = this.pageMap.agentAddress.country;
        if (agentCountry == 'CAN')
            agentCountry = 'CA';
        else if (agentCountry == 'USA')
            agentCountry = 'US';
        let agentPhone = this.pageMap.agent.phone2 ? this.pageMap.agent.phone2 : this.pageMap.agent.phone1;
        let user = Object.assign({}, this.dataService.userData.UserData, { u_phone: this.page3.sphone, u_email: this.page3.semail, u_firstnm: this.page3.sfname, u_lastnm: this.page3.slname });
        let fromAddr = { Address: this.page3.sstreetn + ' ' + this.page3.sstreet, City: this.page3.scity, Province: this.page3.soblast, Country: this.page3.scountry == 'CANADA' ? 'CA' : 'US', Postal: this.page3.szip };
        if (this.page3.sapartn)
            fromAddr.Address = `${this.page3.sapartn}-` + fromAddr.Address;
        let toAddr = this.shipType == 'I' ? { Address: this.pageMap.agentAddress.address, City: this.pageMap.agentAddress.locality, Province: this.pageMap.agentAddress.state, Country: agentCountry, Postal: this.pageMap.agentAddress.postal_code }
            : { Address: this.pageMap.agentAddress.street_number + ' ' + this.pageMap.agentAddress.route, City: this.pageMap.agentAddress.locality, Province: this.pageMap.agentAddress.administrative_area_level_1, Country: this.pageMap.agentAddress.country, Postal: this.pageMap.agentAddress.postal_code };
        let param = {
            ShipmentID: shipid, From: fromAddr, To: toAddr, Weight: this.page1.weight, Height: this.page1.h, Width: this.page1.w, Length: this.page1.l, Company: 'Meest', ContactPhone: this.pageMap.rate.ServiceCode == 'CanPar' ? agentPhone :'4163063522',
            AgentName: this.pageMap.agent.contactname, AgentCompany: this.pageMap.agent.agentname, AgentCode: this.pageMap.agent.agentcode, ServiceCode: this.pageMap.rate.ServiceCode, User: user,
            PickupDateStr: this.pickupWindow ? this.datePipe.transform(this.pickupWindow.jsdate, 'yyyyMMdd') : null
        };
        if (this.shipType == 'D') {
            param.ContactPhone = this.page3.rphone.replace(/[()\s\-]/g, '');
            param.AgentName = this.page3.rfname + ' ' + this.page3.rlname;
        }
        return this.dataService.saveCPShipment(param, this.shipType);
    }

    startQuagga() {
        let me = this;

        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                //target: document.querySelector('#yourElement')    // Or '#yourElement' (optional)
            },
            decoder: {
                //readers: ["codabar_reader"]
                readers: ["code_128_reader"]
            }
        }, function (err) {
            if (err) {
                console.log(err);
                me.showScanner = false;
                alert(err);
                return;
            }
            console.log("Initialization finished. Ready to start");
            Quagga.start();
        });
    }

    setupQuagga() {
        Quagga.onProcessed((result) => {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
                }
            }
        });

        Quagga.onDetected((result) => {
            $('#declaration').val(result.codeResult.code);
            this.scannedData = result.codeResult.code;
            Quagga.stop();
            this.showScanner = false;
        });
    }
}
